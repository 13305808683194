<template>
    <div id="teams-entry" class="page-wrapper" v-if="pageIsLoaded">
        <!-- Team details -->
        <section class="team-details fixed">
            <div class="col col-content left">
                <h1 class="big-title" data-inview="fadeInUp" data-delay="100">
                    {{ content.title }}
                </h1>
                <p class="big-text bold text-row" data-inview="fadeInUp" data-delay="300">
                    <span>{{ content.poste }}</span>
                    <span class="icons">
                        <a
                            :href="content.linkedin"
                            title="LinkedIn"
                            target="_blank"
                            rel="noopener noreferrer"
                            v-if="content.linkedin && content.linkedin !== '#'"
                        >
                            <svg
                                height="30"
                                viewBox="0 0 40 40"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M20 0C8.95431 0 0 8.95431 0 20C0 31.0457 8.95431 40 20 40C31.0457 40 40 31.0457 40 20C40 8.95431 31.0457 0 20 0ZM9.60137 16.5649H14.1338V30.183H9.60137V16.5649ZM14.4323 12.3522C14.4029 11.017 13.448 10 11.8975 10C10.347 10 9.3333 11.017 9.3333 12.3522C9.3333 13.6598 10.317 14.7061 11.8387 14.7061H11.8676C13.448 14.7061 14.4323 13.6598 14.4323 12.3522ZM25.2609 16.2451C28.2435 16.2451 30.4795 18.1919 30.4795 22.3748L30.4793 30.183H25.947V22.8973C25.947 21.0673 25.2912 19.8185 23.6504 19.8185C22.3982 19.8185 21.6524 20.6604 21.3248 21.4735C21.205 21.7649 21.1756 22.1709 21.1756 22.5779V30.1834H16.6426C16.6426 30.1834 16.7023 17.8431 16.6426 16.5653H21.1756V18.4941C21.7771 17.5669 22.8545 16.2451 25.2609 16.2451Z"
                                    fill="#89AEAC"
                                />
                            </svg>
                        </a>
                        <a
                            :href="content.instagram"
                            title="instagram"
                            target="_blank"
                            rel="noopener noreferrer"
                            v-if="content.instagram && content.instagram !== '#'"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 0 35.38 35.38"><g id="ELEMENTS"><circle cx="17.69" cy="17.69" r="17.69" fill="#89AEAC"/><path class="cls-1" fill="#fff" d="M23.18,11a1.22,1.22,0,1,0,1.22,1.21A1.21,1.21,0,0,0,23.18,11Z"/><path class="cls-1" fill="#fff" d="M17.78,12.56a5.11,5.11,0,1,0,5.1,5.11A5.11,5.11,0,0,0,17.78,12.56Zm0,8.38a3.28,3.28,0,1,1,3.27-3.27A3.28,3.28,0,0,1,17.78,20.94Z"/><path class="cls-1" fill="#fff" d="M21.83,28H13.55a6.24,6.24,0,0,1-6.23-6.23V13.53a6.24,6.24,0,0,1,6.23-6.24h8.28a6.24,6.24,0,0,1,6.23,6.24v8.28A6.24,6.24,0,0,1,21.83,28ZM13.55,9.25a4.28,4.28,0,0,0-4.28,4.28v8.28a4.28,4.28,0,0,0,4.28,4.28h8.28a4.28,4.28,0,0,0,4.28-4.28V13.53a4.28,4.28,0,0,0-4.28-4.28Z"/></g></svg>
                        </a>
                        <a
                            :href="content.facebook"
                            title="facebook"
                            target="_blank"
                            rel="noopener noreferrer"
                            v-if="content.facebook && content.facebook !== '#'"
                        >
                             <svg
                                height="30"
                                viewBox="0 0 40 40"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M20 0C8.95431 0 0 8.95431 0 20C0 31.0457 8.95431 40 20 40C31.0457 40 40 31.0457 40 20C40 8.95431 31.0457 0 20 0ZM22.0846 20.8785V31.7596H17.5825V20.8789H15.3333V17.1293H17.5825V14.878C17.5825 11.819 18.8526 10 22.461 10H25.465V13.7501H23.5873C22.1826 13.7501 22.0897 14.2741 22.0897 15.2521L22.0846 17.1288H25.4863L25.0882 20.8785H22.0846Z"
                                    fill="#89AEAC"
                                />
                            </svg>
                        </a>
                    </span>
                </p>
                <p class="regular-text" data-inview="fadeInUp" data-delay="400">
                    <span v-html="content.description"></span>
                </p>

                <p class="big-text bold" data-inview="fadeInUp" data-delay="100">
                    {{ $t('Membre des associations dentaires suivantes') }}
                </p>

                <div class="logos" data-inview="fadeInUp" data-delay="200">
                    <img
                        v-for="(item, i) in content.associationsDentaires"
                        :key="i"
                        :src="item.image"
                        :alt="item.titre"
                    />
                </div>

                <p class="big-text bold" data-inview="fadeInUp" data-delay="100">
                    {{ $t('Ses collègues dentistes') }}
                </p>

                <div class="other-members">
                    <router-link
                        class="membre-row"
                        :to="item.jsonUrl"
                        :title="item.title"
                        v-for="(item, i) in equipeCollegues"
                        :key="i"
                        data-inview="fadeInUp"
                        :data-delay="i * 200"
                    >
                        <div class="col col-img">
                            <img :src="item.image.url" :alt="item.image.titre" />
                        </div>
                        <div class="col col-content">
                            <h2 class="small-title">{{ item.title }}</h2>
                            <p class="big-text">{{ item.poste }}</p>
                            <svg
                                class="plus"
                                height="32"
                                viewBox="0 0 42 42"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <circle cx="21" cy="21" r="21" fill="#89AEAC" />
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M21 28C21.6486 28 22.1852 27.4635 22.1852 26.8148V22.1852H26.8148C27.4635 22.1852 28 21.6486 28 21C28 20.3514 27.4635 19.8148 26.8148 19.8148H22.1852V15.1852C22.1852 14.5365 21.6486 14 21 14C20.3514 14 19.8148 14.5365 19.8148 15.1852V19.8148H15.1852C14.5365 19.8148 14 20.3514 14 21C14 21.6486 14.5365 22.1852 15.1852 22.1852H19.8148V26.8148C19.8148 27.4635 20.3514 28 21 28V28Z"
                                    fill="#F2F9F7"
                                />
                            </svg>
                        </div>
                    </router-link>
                </div>
            </div>
            <div class="col col-image right">
                <img :src="content.imageDetails.url" :alt="content.imageDetails.titre" />
            </div>
        </section>
    </div>
    <PageLoader v-else />
</template>

<script>
import { initInViewAnimations } from '@/plugins/InViewAnimations'

import { mapState, mapGetters } from 'vuex'
import PageLoader from '@/components/PageLoader.vue'

function pageScrollHack() {
    if (!document.querySelector('.team-details')) {
        return
    }

    var screen = window.innerHeight
    var equipeDetails = document.querySelector('.left').offsetHeight
    var pos = window.scrollY + screen - equipeDetails

    if (pos > 150) {
        document.querySelector('.team-details').classList.remove('fixed')
    } else {
        document.querySelector('.team-details').classList.add('fixed')
        document.querySelector('.team-details .right').style.top = '90px'
    }

    if (window.matchMedia('(max-width: 959px)').matches) {
        document.querySelector('.team-details').classList.remove('fixed')
        document.querySelector('.team-details .right').style.top = '0px'
    }
}

export default {
    name: 'TeamsEntry',

    components: {
        PageLoader,
    },

    watch: {
        pageIsLoaded: {
            handler(pageIsLoaded) {
                if (pageIsLoaded) {
                    // Page load buffer
                    setTimeout(() => {
                        initInViewAnimations()

                        if (!window.matchMedia('(max-width: 959px)').matches) {
                            window.onscroll = function () {
                                if (!document.querySelector('.team-details')) {
                                    return
                                }

                                // window.requestAnimationFrame(pageScrollHack);
                                pageScrollHack()
                            }
                            pageScrollHack()
                        }
                    }, 155)
                }
            },
            immediate: true,
        },
    },

    computed: {
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        equipeCollegues() {
            if (!this.globalsIsLoaded) {
                return []
            }
            return this.globals.equipeMembres.data.filter(i => i.id !== this.content.id && i.poste === 'Dentiste')
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.page-wrapper {
    min-height: 90vh;
}
</style>
